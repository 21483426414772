<template>
  <div class="scope-container" v-loading="loading">
    <div class="d-flex align-items-center justify-content-between">
      <h3>{{ session.name }}</h3>
      <div class="d-flex gap-1">
        <span class="text-secondary">Attendees</span>
        <span>{{ users.size }} / {{ training.max_attendance }}</span>
      </div>
    </div>
    <!-- {{ training.users }} -->
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div
        style="
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;
        "
      >
        <label
          class="filter_btn"
          :class="{ selected: selectedFilter === 'users' }"
          @click="filterByUsers"
          >{{ $t("Users") }}</label
        >
        <!-- <label
          class="filter_btn"
          :class="{ selected: selectedFilter === 'levels' }"
          v-if="this.subscribeALL"
          @click="filterByLevels"
          >{{ $t("Levels") }}</label
        >
        <label
          class="filter_btn"
          :class="{ selected: selectedFilter === 'roles' }"
          @click="filterByRoles"
          >{{ $t("Roles") }}</label
        > -->
      </div>
      <el-checkbox
        v-model="selectAll"
        :label="$t('Select All')"
        size="large"
        data-testid="selectAll"
      />
    </div>
    <div>
      <el-select
        data-testid="users-tree-select"
        v-if="selectedFilter === 'users'"
        style="width: 100%"
        v-model="selectedUsers"
        multiple
        :render-after-expand="false"
        show-checkbox
        check-strictly
        check-on-click-node
        @remove-tag="removeUser"
        :placeholder="$t('Select Users')"
        :disabled="
          selectAll ||
          (preSelectedUsers &&
            preSelectedUsers.length > 0 &&
            isSwitchOn === 'no')
        "
      >
        <el-option
          v-for="(item, index) in usersOptions.filter((el) => {
            const [email, level_name, job_description] =
              el.value.info.split(' - ');

            return training.users_access.some(
              (access) =>
                access.email === email.trim() &&
                access.level_name === level_name.trim() &&
                access.job_description === job_description.trim()
            );
          })"
          :disabled="
            training.users
              ? training.users.some(
                  (el) =>
                    el.user_id == item.value.id &&
                    el.sessions_id == this.session.id
                )
              : false
          "
          :key="index"
          :label="item.label"
          :value="`${item.value.id}-${item.value.info}`"
        ></el-option>
      </el-select>

      <el-tree-select
        v-else-if="selectedFilter === 'levels' && this.subscribeALL"
        filterable
        style="width: 100%"
        v-model="selectedLevels"
        :data="globalStore.scopeLevelsOptions"
        multiple
        :render-after-expand="false"
        show-checkbox
        check-strictly
        check-on-click-node
        :placeholder="$t('Select Levels')"
        :disabled="
          selectAll ||
          (preSelectedUsers &&
            preSelectedUsers.length > 0 &&
            isSwitchOn === 'no')
        "
      />
      <el-tree-select
        v-else-if="selectedFilter === 'roles'"
        filterable
        style="width: 100%"
        v-model="selectedRoles"
        :data="globalStore.scopeRolesOptions"
        multiple
        :render-after-expand="false"
        show-checkbox
        check-strictly
        check-on-click-node
        :placeholder="$t('Select Role')"
        :disabled="
          selectAll ||
          (preSelectedUsers &&
            preSelectedUsers.length > 0 &&
            isSwitchOn === 'no')
        "
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { OrganizationsService } from "@/services/organizations";
import { useGlobalStore } from "@/stores/store";
export default {
  name: "SubscriptionScopeSelections",
  data() {
    return {
      globalStore: useGlobalStore(),
      selectedFilter: "users",
      selectAll: false,
      usersOptions: [],
      selectedUsers: [],
      levelsOptions: [],
      selectedLevels: [],
      rolesOptions: [],
      selectedRoles: [],
      users: new Set(),
      loading: false,
    };
  },
  props: {
    preSelectedUsers: Array,
    show: Boolean,
    isSwitchOn: String,
    session: Object,
    training: Object,
    subscribeALL: Boolean,
    subscribeTeam: Boolean,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
    }),
  },
  methods: {
    filterByUsers() {
      this.selectedFilter = "users";
    },
    filterByLevels() {
      this.selectedFilter = "levels";
    },
    filterByRoles() {
      this.selectedFilter = "roles";
    },
    removeUser(tagValue) {
      if (this.training.users.some((el) => el.user_id == tagValue)) {
        this.selectedUsers.push(tagValue);
      }
    },
    async getOrgMembers() {
      this.loading = true;
      try {
        const { data } = await OrganizationsService.list_org_members({
          organization_name:
            this.currentOrganization.name ||
            this.currentOrganization.organization,
          project_name: this.currentProject.name,
        });

        const usersOptions = [];
        const response = data.data;
        if (this.usersOptions.length > 0) {
          this.usersOptions = [];
        }

        const seenUserInfos = new Set();
        response.forEach((user) => {
          user.org_roles.forEach((role) => {
            const userInfo = `${user.email} - ${role.level_name} - ${role.job_description}`;
            if (!seenUserInfos.has(userInfo)) {
              seenUserInfos.add(userInfo);
              usersOptions.push({
                value: { id: user._id, info: userInfo },
                label: userInfo,
              });
            }
          });
        });

        this.usersOptions = Object.assign([], usersOptions);
        this.globalStore.setScopeUsersOptions(usersOptions);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
  },
  mounted() {
    this.training.users.forEach((user) => {
      if (
        this.preSelectedUsers.includes(user.user_id) &&
        user.sessions_id == this.session.id
      ) {
        this.selectedUsers.push(user.user_id);
        this.users.add(user.user_id);
      }
    });

    this.userCount = this.selectedUsers.length;
  },
  created() {
    this.getOrgMembers();
  },
  watch: {
    users(val) {
      this.$emit("selectSubUsers", this.users);
    },
    show(val) {
      if (!val) {
        this.selectedFilter = "users";
        this.selectedUsers = [];
        this.selectedLevels = [];
        this.selectedRoles = [];
        this.selectAll = false;
      }
      if (val) {
        this.training.users.forEach((user) => {
          // if(user.)
          this.users.add(user.user_id);
        });
      }
    },
    selectedUsers(val, old_val) {
      this.training.users;
      this.selectedUsers.forEach((el) => this.users.add(el));
      if (val.length < old_val.length) {
        let removedRole = old_val.find((el) => !val.includes(el));

        this.currentOrganization.tree_2.forEach((level) => {
          level.members.forEach((member) => {
            if (
              member.id == removedRole &&
              !this.selectedLevels.includes(level.ob_id) &&
              !this.selectedRoles.includes(member.role)
            ) {
              this.users.delete(member.id);
            }
          });
        });
      }
      this.$emit("usersChanged", this.selectedUsers);
      this.$emit("selectSubUsers", this.users);
    },
    selectedLevels(val, old_val) {
      if (this.subscribeALL) {
        val.forEach((level) => {
          this.currentOrganization.tree_2
            .find((el) => el.ob_id == level)
            .members.forEach((member) => {
              if (this.training.users_access.includes(member.id)) {
                this.users.add(member.id);
              }
            });
        });

        if (val.length < old_val.length) {
          let removedLevel = old_val.find((el) => !val.includes(el));
          let membersToRemove = this.currentOrganization.tree_2.find(
            (el) => el.ob_id == removedLevel
          ).members;
          membersToRemove.forEach((el) => {
            if (
              !this.selectedRoles.includes(el.role) &&
              !this.selectedUsers.includes(el.id)
            ) {
              this.users.delete(el.id);
            }
          });
        }
      }
      this.$emit("levelsChanged", this.selectedLevels);
      this.$emit("selectSubUsers", this.users);
    },
    selectedRoles(val, old_val) {
      if (val.length) {
        if (this.subscribeALL) {
          val.forEach((role) => {
            this.currentOrganization.tree_2.forEach((level) => {
              level.members.forEach((member) => {
                if (member.role == role) {
                  this.users.add(member.id);
                }
              });
            });
          });
        } else {
          val.forEach((role) => {
            this.currentOrganization.tree_2.forEach((level) => {
              level.members.forEach((member) => {
                if (
                  member.role == role &&
                  level.ob_id ==
                    this.userData.current_job_description.level.level_id &&
                  this.training.users_access.includes(member.id)
                ) {
                  this.users.add(member.id);
                }
              });
            });
          });
        }
      }
      if (val.length < old_val.length) {
        let removedRole = old_val.find((el) => !val.includes(el));
        this.currentOrganization.tree_2.forEach((level) => {
          level.members.forEach((member) => {
            if (
              member.role == removedRole &&
              !this.selectedLevels.includes(level.ob_id) &&
              !this.selectedUsers.includes(member.id)
            ) {
              this.users.delete(member.id);
            }
          });
        });
      }
      this.$emit("rolesChanged", this.selectedRoles);
      this.$emit("selectSubUsers", this.users);
    },
    preSelectedUsers() {
      this.selectedUsers = this.preSelectedUsers;
    },
    selectedFilter(val, old_val) {
      if (val != old_val) {
        // this.userCount=0;
      }
    },
    selectAll(val, old_val) {
      if (val) {
        if (this.subscribeALL) {
          this.currentOrganization.tree_2.forEach((level) => {
            level.members.forEach((member) => {
              if (this.training.users_access.includes(member.id)) {
                this.users.add(member.id);
              }
            });
          });
        } else {
          this.currentOrganization.tree_2.forEach((level) => {
            if (
              level.ob_id ==
              this.userData.current_job_description.level.level_id
            ) {
              level.members.forEach((member) => {
                if (this.training.users_access.includes(member.id)) {
                  this.users.add(member.id);
                }
              });
            }
          });
        }
      } else {
        this.currentOrganization.tree_2.forEach((level) => {
          level.members.forEach((member) => {
            if (
              !this.selectedRoles.includes(member.role) &&
              !this.selectedLevels.includes(level.ob_id) &&
              !this.selectedUsers.includes(member.id)
            ) {
              this.users.delete(member.id);
            }
          });
        });
      }
      this.$emit("selectSubUsers", this.users);
    },
  },
};
</script>

<style>
.scope-container {
  margin-top: 10px;
  width: 100%;
  background: #f7fafe;
  border: 1px solid #9ec2f1;
  padding: 20px;
  margin-bottom: 10px;
}
.filter_btn {
  cursor: pointer;
  font-size: 14px;
}
.filter_btn:hover {
  color: #0071ce;
}
.filter_btn.selected {
  color: #0071ce;
  font-weight: bold;
  letter-spacing: -0.02em; /* Adjust based on your font and preference */
  border-bottom: 2px solid #0071ce;
}
</style>
