<template>
  <div class="main">
    <h2>Training courses</h2>
    <div class="head--panel">
      <div
        class="sections--panel"
        v-if="subscribeALL || subscribeTeam || subscribeMe"
      >
        <button
          v-for="(item, index) in sections"
          :key="index"
          @click="setSection(item.id)"
          :class="['section--btn', selectedSection == item.id && 'active']"
        >
          {{ item.label }}
        </button>
      </div>
    </div>

    <div class="trainings">
      <div v-if="data.length == 0 || data.every((el) => !el.trainings.length)">
        <el-empty description="No Training available"></el-empty>
      </div>
      <el-collapse
        v-else
        class="trainings--collapse"
        v-model="activeNames"
        @change="handleChange"
      >
        <el-collapse-item
          v-for="(item, dataIndex) in data"
          v-show="item.trainings.length"
          key="index"
          :name="item.group"
        >
          <template #title>
            <h3>{{ item.group }}</h3>
          </template>
          <div class="module--cards--container">
            <el-button class="nav--btn" circle>
              <i class="fa fa-chevron-left left"></i>
            </el-button>

            <TrainingCard
              @openTrainingModal="openTrainingModal"
              v-for="(training, index) in item.trainings"
              :key="index"
              :training="training"
            ></TrainingCard>
            <el-button class="nav--btn right" circle>
              <i class="fa fa-chevron-right"></i>
            </el-button>
          </div>

          <CButton
            :class="[
              'ra-button',
              'm-auto',
              'mt-2',
              'bg-transparent',
              this.disabledBtns.some(
                (el) => el == String('button-' + dataIndex)
              )
                ? 'text-muted disabled'
                : 'text-primary',
            ]"
            color="primary"
            :ref="'button-' + dataIndex"
            :disabled="disabledBtns.some((el) => el == 'button-' + dataIndex)"
            @click="
              loadMore(
                item.trainings.length,
                item.group,
                10,
                'button-' + dataIndex
              )
            "
            >{{ $t(" Load More") }}
          </CButton>
        </el-collapse-item>
      </el-collapse>
    </div>
    <TrainingDetailsModal
      :selectedSection="selectedSection"
      @closeTrainingModal="closeTrainingModal"
      :trainingModalOpen="trainingModalOpen"
      :selectedTraining="selectedTraining"
      :subscribeMe="subscribeMe"
      :subscribeTeam="subscribeTeam"
      :subscribeALL="subscribeALL"
    />
  </div>
</template>
<script>
import { ElLoading } from "element-plus";
import ModuleCard from "./components/ModuleCard.vue";
import TrainingCard from "./components/TrainingCard.vue";
import TrainingDetailsModal from "./components/TrainingDetailsModal.vue";
import { TrainingService } from "../../services/training";
import { mapState } from "vuex";
import { useUserStore } from "@/stores/UserStore";

export default {
  components: { ModuleCard, TrainingDetailsModal, TrainingCard },
  data() {
    return {
      userStore: useUserStore(),

      selectedSection: 0,
      sections: [
        { id: 0, label: "Available trainings" },
        { id: 1, label: "My trainings" },
      ],
      data: [],
      groups: [],
      activeNames: [],
      group: [],
      status: [],
      disabledBtns: [],
      module: "",
      description: "",
      title: "",
      availbleTrainings: [],
      myTrainings: [],
      trainingModalOpen: false,
      selectedTraining: null,
      permissions: [],
    };
  },

  computed: {
    ...mapState({
      userData: (state) => state.userData,
      selectedOrg: (state) => state.selectedOrg,
      selectedProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      isAdmin: (state) => state.isAdmin,
    }),
    subscribeMe() {
      return (
        this.permissions.length &&
        this.permissions.some((el) => el.name == "subscribeMe" && el.status)
      );
    },
    subscribeTeam() {
      return (
        this.permissions.length &&
        this.permissions.some((el) => el.name == "subscribeTeam" && el.status)
      );
    },
    subscribeALL() {
      return (
        this.permissions.length &&
        this.permissions.some((el) => el.name == "subscribeALL" && el.status)
      );
    },
  },
  mounted() {
    this.getTrainings();
    this.permissions =
      this.userStore.currentPermissions.length &&
      this.userStore.currentPermissions[0].find((el) => el.name == "Training")
        .permissions;
    this.selectedSection =
      this.subscribeALL || this.subscribeTeam || this.subscribeMe
        ? this.selectedSection
        : 1;
  },
  methods: {
    setSection(id) {
      this.selectedSection = id;
    },
    async getTrainings() {
      let payload = {
        groups: this.group,
        title: this.title,
        description: this.description,
        statuses: this.status,
        module: this.module,
        page: 1,
        count: 10,
      };

      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      this.trainings = [];
      const { res, data } = await TrainingService.get_home_trainings();
      if (res.status == 200) {
        this.availbleTrainings = data;
        this.myTrainings = this.availbleTrainings.map((el) =>
          el.trainings.filter((training) =>
            training.users
              ? training.users.some(
                  (user) =>
                    user.user_id === this.userData.id &&
                    user.level === this.userData.current_level &&
                    user.job_description === this.userData.current_level_role
                )
              : false
          )
        );

        this.data =
          this.selectedSection == 0 ? this.availbleTrainings : this.myTrainings;
        this.activeNames = this.data.map((el) => el.group);
      }
      loading.close();
    },
    async loadMore(length, group, count, btn) {
      let page = Math.ceil(length / count) + 1;
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      const { res, data } = await TrainingService.get_home_trainings_next({
        page,
        group,
        count,
      });
      this.trainings = [];

      if (res.status == 200) {
        if (data.length > 0) {
          this.availbleTrainings = this.data.map((el) => {
            if (el.group == group) {
              data.forEach((training) => el.trainings.push(training));
              return el;
            } else {
              return el;
            }
          });

          this.myTrainings = this.availbleTrainings.map((el) =>
            el.trainings.filter((training) =>
              training.users
                ? training.users.some(
                    (user) =>
                      user.user_id === this.userData.id &&
                      user.level === this.userData.current_level &&
                      user.job_description === this.userData.current_level_role
                  )
                : false
            )
          );
          this.data =
            this.selectedSection == 0
              ? this.availbleTrainings
              : this.myTrainings;
        } else {
          this.disabledBtns.push(btn);
        }
      }
      loading.close();
    },
    openTrainingModal(training) {
      this.selectedTraining = training;
      this.trainingModalOpen = true;
    },
    closeTrainingModal(status) {
      this.selectedTraining = null;
      this.trainingModalOpen = false;
      if (status) {
        this.getTrainings();
      }
    },
  },
  watch: {
    selectedSection(val, old_val) {
      if (val != old_val) {
        this.myTrainings = this.availbleTrainings.map((el) => ({
          ...el,
          trainings: el.trainings.filter((training) =>
            training.users
              ? training.users.some(
                  (user) =>
                    user.user_id === this.userData.id &&
                    user.level === this.userData.current_level &&
                    user.job_description === this.userData.current_level_role
                )
              : false
          ),
        }));
        this.data = val == 0 ? this.availbleTrainings : this.myTrainings;
      }
    },
  },
};
</script>
<style>
.trainings--collapse {
  border: none;
  outline: none;
}

.el-collapse-item__header:focus {
  outline: none;
}

.trainings--collapse .el-collapse-item__header,
.trainings--collapse .el-collapse-item__wrap {
  background-color: transparent;
}

.trainings--collapse .el-collapse-item__arrow {
  width: fit-content;
  margin: 0 15px;
}
.trainings--collapse .el-collapse-item__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.disabled {
  cursor: not-allowed;
}
.trainings--collapse .el-collapse-item__header {
  height: 60px;
}
</style>
<style scoped>
.main {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: calc(100vh - 120px);
}

.head--panel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sections--panel {
  display: flex;
  gap: 20px;
}

.section--btn {
  border: none;
  background: none;
  padding: 5px 0;
  outline: none;
}

.section--btn.active {
  color: #0071ce;
  position: relative;
}

.section--btn.active::before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #0071ce;
  position: absolute;
  bottom: 0;
}

.module--cards--container {
  width: 100%;
  overflow: auto;
  padding: 0px 8px;
  gap: 10px;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}

.nav--btn {
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  z-index: 9999;
  display: none;
}

.nav--btn.left {
  left: -10px;
}

.nav--btn.right {
  right: 10px;
}

.nav--btn i {
  margin: 0;
}
</style>
