<template>
  <el-dialog
    v-model="dialogVisible"
    class="Training--details--modal"
    width="800"
    :center="true"
    :align-center="true"
    :show-close="false"
    :before-close="() => $emit('closeTrainingModal', shouldUpdate)"
  >
    <!-- :before-close="$emit('closeTrainingModal')" -->
    <template #header="{ close, titleId, titleClass }">
      <div class="d-flex justify-content-between">
        <h3 style="margin-left: 1rem" :id="titleId">{{ training.name }}</h3>
      </div>
    </template>
    <div class="main" style="margin: 1rem">
      <p class="description">
        {{ training.description }}
      </p>
      <div class="variables">
        <div class="variable">
          <label for="">Group</label>
          <span>{{ training.group }}</span>
        </div>
        <div class="variable">
          <label for="">Average session time</label>
          <span>{{ totalDuration }}</span>
        </div>
        <div class="variable">
          <label for="">Points</label>
          <span>{{
            training.sessions[0].modules.reduce(
              (prev, next) => prev + next.points,
              0
            )
          }}</span>
        </div>
        <div class="variable">
          <label for="">Attendees (Min / Max)</label>
          <span
            >{{ training.min_attendance }}/{{ training.max_attendance }}</span
          >
        </div>
      </div>
      <div class="module--content" v-if="selectedSection == 0">
        <h4>Module content</h4>
        <table>
          <thead>
            <tr class="head--tr">
              <th>Modules</th>
              <th v-for="(S, index) in training.sessions" :key="index">
                Session {{ index + 1 }}
                <!-- {{ training.sessions.find(el=>el.id==item.id).modules }} -->
              </th>
            </tr>
          </thead>
          <tbody v-loading="loading">
            <tr class="body--tr">
              <td></td>
              <td v-for="(session, index) in training.sessions" :key="index">
                <el-dropdown
                  split-button
                  type="primary"
                  size="small"
                  v-if="
                    (subscribeALL || subscribeTeam) &&
                    subList.includes(session.id)
                  "
                  @click="openScope(session)"
                >
                  Subscribe Others
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        @click="unJoin_training(training.id, session.id)"
                        >Unsubscribe</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <el-button
                  type="primary"
                  v-else-if="
                    (subscribeALL || subscribeTeam) &&
                    !subList.includes(session.id)
                  "
                  :class="
                    subList.some((el) => el == session.id)
                      ? 'bg-transparent text-primary'
                      : ''
                  "
                  @click="openScope(session)"
                  round
                  >{{
                    subList.some((el) => el == session.id)
                      ? "Subscribed"
                      : "Subscribe"
                  }}</el-button
                >
                <el-button
                  type="primary"
                  v-else-if="!subscribeALL && !subscribeTeam && subscribeMe"
                  :class="
                    subList.some((el) => el == session.id)
                      ? 'bg-transparent text-primary'
                      : ''
                  "
                  @click="
                    subList.some((el) => el == session.id)
                      ? unJoin_training(training.id, session.id)
                      : Join_training(training.id, session.id)
                  "
                  round
                  >{{
                    subList.some((el) => el == session.id)
                      ? "Subscribed"
                      : "Subscribe"
                  }}
                </el-button>
                <el-button
                  type="primary"
                  v-else-if="!subscribeALL && !subscribeTeam && subscribeMe"
                  :class="
                    subList.some((el) => el == session.id)
                      ? 'bg-transparent text-primary'
                      : ''
                  "
                  @click="
                    subList.some((el) => el == session.id)
                      ? unJoin_training(training.id, session.id)
                      : Join_training(training.id, session.id)
                  "
                  round
                  >{{
                    subList.some((el) => el == session.id)
                      ? "Subscribed"
                      : "Subscribe"
                  }}
                </el-button>

                <el-button type="primary" class="d-none" disabled round
                  >Subscribed
                </el-button>
                <!-- v-if="!training.users || !training.users.some(el => el.user_id == userData.id && el.sessions_id == session.id)" -->
              </td>
            </tr>
            <tr
              class="body--tr"
              v-for="(module, index) in training.sessions[0].modules"
              :key="index"
            >
              <td>{{ module.name }}</td>
              <td v-for="(session, index) in training.sessions" :key="index">
                <div class="session--detail">
                  <span class="MD--SS--Date">
                    {{
                      formDate(
                        training.sessions
                          .find((el) => el.id == session.id)
                          .modules.find(
                            (el) => el.module_id == module.module_id
                          ).date
                      )
                    }}</span
                  >
                  <span class="MD--SS--time"
                    >{{
                      training.sessions
                        .find((el) => el.id == session.id)
                        .modules.find((el) => el.module_id == module.module_id)
                        .time
                    }}
                    ({{
                      training.sessions
                        .find((el) => el.id == session.id)
                        .modules.find((el) => el.module_id == module.module_id)
                        .duration
                    }}h)</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div ref="subscriptionScopeSelectionsRef">
          <SubscriptionScopeSelections
            :session="selectedSession"
            :show="scopeOpened"
            v-if="scopeOpened"
            :training="selectedTraining"
            :preSelectedUsers="selectedUsers"
            @usersChanged="selectedUsersChanged"
            @levelsChanged="selectedLevelsChanged"
            @rolesChanged="selectedRolesChanged"
            @selectAll="selectedAllUsers"
            @selectSubUsers="selectSubUsers"
            :subscribeALL="subscribeALL"
            :subscribeTeam="subscribeTeam"
          />
        </div>
      </div>
      <div v-else class="d-flex flex-column gap-3">
        <div v-for="(item, index) in findSessionModules()" :key="index">
          <div
            class="Module--card"
            :style="[`cursor:pointer;border-color:${training.color}`]"
          >
            <div class="header">
              <h4>{{ item.name }}</h4>
            </div>
            <div class="variables">
              <div class="variable">
                <label for="">Date</label>
                <span>{{ item.date }}</span>
              </div>
              <div class="variable">
                <label for="">Time</label>
                <span>{{ item.time }}</span>
              </div>
              <div class="variable">
                <label for="">Points</label>
                <span>{{ item.points }}</span>
              </div>
              <div class="variable">
                <label for="">Duration</label>
                <span>{{ item.duration }}h</span>
              </div>
              <div class="variable">
                <label for="">Note</label>
                <span>{{ item.note }}</span>
              </div>
            </div>
            <p class="description">
              {{ item.description }}
            </p>
            <div class="points--container">
              <!-- @click="downloadFile(file.id)" -->
              <div
                v-for="(file, index) in item.files"
                :key="index"
                class="file"
                @click.prevent="downloadFileWhenClicking(item.files, file)"
                :style="[`cursor:pointer;border-color:${training.color}`]"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.08333 8.62695C6.92862 8.62695 6.78025 8.68841 6.67085 8.79781C6.56146 8.9072 6.5 9.05558 6.5 9.21029C6.5 9.365 6.56146 9.51337 6.67085 9.62277C6.78025 9.73216 6.92862 9.79362 7.08333 9.79362H12.9167C13.0714 9.79362 13.2197 9.73216 13.3291 9.62277C13.4385 9.51337 13.5 9.365 13.5 9.21029C13.5 9.05558 13.4385 8.9072 13.3291 8.79781C13.2197 8.68841 13.0714 8.62695 12.9167 8.62695H7.08333ZM6.5 11.5436C6.5 11.3889 6.56146 11.2405 6.67085 11.1311C6.78025 11.0217 6.92862 10.9603 7.08333 10.9603H12.9167C13.0714 10.9603 13.2197 11.0217 13.3291 11.1311C13.4385 11.2405 13.5 11.3889 13.5 11.5436C13.5 11.6983 13.4385 11.8467 13.3291 11.9561C13.2197 12.0655 13.0714 12.127 12.9167 12.127H7.08333C6.92862 12.127 6.78025 12.0655 6.67085 11.9561C6.56146 11.8467 6.5 11.6983 6.5 11.5436ZM6.5 13.877C6.5 13.7222 6.56146 13.5739 6.67085 13.4645C6.78025 13.3551 6.92862 13.2936 7.08333 13.2936H9.41667C9.57138 13.2936 9.71975 13.3551 9.82915 13.4645C9.93854 13.5739 10 13.7222 10 13.877C10 14.0317 9.93854 14.18 9.82915 14.2894C9.71975 14.3988 9.57138 14.4603 9.41667 14.4603H7.08333C6.92862 14.4603 6.78025 14.3988 6.67085 14.2894C6.56146 14.18 6.5 14.0317 6.5 13.877Z"
                    :fill="`${training.color}`"
                  />
                  <path
                    d="M11.75 0.460938H5.33333C4.71449 0.460938 4.121 0.70677 3.68342 1.14435C3.24583 1.58194 3 2.17543 3 2.79427V16.7943C3 17.4131 3.24583 18.0066 3.68342 18.4442C4.121 18.8818 4.71449 19.1276 5.33333 19.1276H14.6667C15.2855 19.1276 15.879 18.8818 16.3166 18.4442C16.7542 18.0066 17 17.4131 17 16.7943V5.71094L11.75 0.460938ZM11.75 1.6276V3.96094C11.75 4.42507 11.9344 4.87019 12.2626 5.19837C12.5908 5.52656 13.0359 5.71094 13.5 5.71094H15.8333V16.7943C15.8333 17.1037 15.7104 17.4004 15.4916 17.6192C15.2728 17.838 14.9761 17.9609 14.6667 17.9609H5.33333C5.02391 17.9609 4.72717 17.838 4.50838 17.6192C4.28958 17.4004 4.16667 17.1037 4.16667 16.7943V2.79427C4.16667 2.48485 4.28958 2.18811 4.50838 1.96931C4.72717 1.75052 5.02391 1.6276 5.33333 1.6276H11.75Z"
                    :fill="`${training.color}`"
                  />
                </svg>
                {{ file.name }}
              </div>
            </div>
            <!-- <div class="duration--container">
                            {{ getObj(item.id).date}} - {{ getObj(item.id).time }}
                        </div> -->
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div
        class="dialog-footer d-flex gap-3 align-items-center justify-content-end"
        style="margin: 0 1rem"
      >
        <el-button
          class="border-0 text-primary p-0 closeBtn"
          @click="$emit('closeTrainingModal', shouldUpdate)"
          >Cancel</el-button
        >
        <CButton
          class="ra-button"
          size="sm"
          color="primary"
          v-if="scopeOpened"
          @click="Join_training(training.id, selectedSession.id)"
        >
          Confirm Training
        </CButton>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import moment from "moment";
import { TrainingService } from "../../../services/training";
import { FilesService } from "@/services/azureFiles";
import { mapState } from "vuex";
import { showMessage } from "../../../utils/helpers";
import SubscriptionScopeSelections from "../../../components/SubscriptionScopeSelections.vue";
import { useGlobalStore } from "@/stores/store";
import { UsersService } from "@/services/users";

export default {
  name: "TrainingDetailsModal",
  props: [
    "trainingModalOpen",
    "selectedTraining",
    "selectedSection",
    "subscribeMe",
    "subscribeTeam",
    "subscribeALL",
  ],
  emits: ["closeTrainingModal"],
  components: { SubscriptionScopeSelections },
  data() {
    return {
      dialogVisible: false,
      training: {},
      subList: [],
      activeUser: {},
      shouldUpdate: false,
      selectedUsers: [],
      selectedSession: {},
      selectedLevels: [],
      globalStore: useGlobalStore(),
      selectedSubsUsers: [],
      selectedRoles: [],
      selectedAllUsers: [],
      scopeOpened: false,
      canSubscribeForTeam: true,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      selectedOrg: (state) => state.selectedOrg,
      selectedProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      isAdmin: (state) => state.isAdmin,
    }),

    totalDuration() {
      let Durations = [];
      Object.keys(this.training).includes("sessions")
        ? this.training.sessions.map((session) => {
            session.modules.map((module) =>
              Durations.push(Number(module.duration))
            );
          })
        : 0;
      const sum = Durations.reduce((a, b) => a + b, 0);
      const avg = sum / Durations.length || 0;
      return (
        moment
          .duration({ hours: String(avg).split(".")[0] })
          .add(moment.duration({ minutes: String(avg).split(".")[1] }))
          .get("hours") +
        ":" +
        moment
          .duration({ hours: String(avg).split(".")[0] })
          .add(moment.duration({ minutes: String(avg).split(".")[1] }))
          .get("minutes")
      );
    },
    totalPoints() {
      return this.training
        ? this.training.sessions.reduce(
            (acc, cur) =>
              acc +
              cur.modules.reduce((prev, next) => prev.points + next.points, 0),
            0
          )
        : 0;
    },
  },
  mounted() {
    this.getActiveUser();
  },
  methods: {
    async getActiveUser() {
      const { data } = await UsersService.getActiveUserInfo();
      this.activeUser = data;
    },
    getObj(id) {
      let sessions = this.selectedTraining.sessions.flat();
      let modules = [];
      sessions.map((el) => modules.push(el.modules));
      modules = modules.flat();

      return modules.find((el) => el.module_id == id);
    },
    selectedUsersChanged(selectedUsers) {
      this.selectedUsers = selectedUsers;
    },
    selectSubUsers(selectedUsers) {
      this.selectedSubsUsers = selectedUsers;
    },
    selectedLevelsChanged(selectedLevels) {
      this.selectedLevels = selectedLevels;
    },
    selectedRolesChanged(selectedRoles) {
      this.selectedRoles = selectedRoles;
    },
    selectedAllUsers(selectAll) {
      this.selectedAll = selectAll;
    },
    async Join_training(training_id, session_id) {
      this.loading = true;

      // Transform selectedSubsUsers into the desired format
      const usersArray = Array.from(this.selectedSubsUsers)
        .map((userStr) => {
          const parts = userStr.split("-");
          if (parts.length < 4) {
            console.error("Unexpected user string format:", userStr);
            return null; // or handle this case as needed
          }

          const id = parts[0].trim();
          const email = parts[1].trim();
          const level = parts.slice(2, -1).join("-").trim(); // Assuming level name may contain hyphens
          const job_description = parts[parts.length - 1].trim();

          return { id, level, job_description };
        })
        .filter((user) => user !== null);

      const { res, data } = await TrainingService.Join_training({
        training_id: training_id,
        session_id: session_id,
        users:
          this.subscribeALL || this.subscribeTeam
            ? usersArray
            : [{ id: this.activeUser.id }],
      });

      if (res.status == 200 && data.success) {
        showMessage("success", data.message);
        if (data.message == "User subscribed training successfully") {
          this.subList.push(session_id);
        }
        this.shouldUpdate = true;
        this.$emit("closeTrainingModal", this.shouldUpdate);
      } else {
        showMessage("error", data.message);
      }

      this.loading = false;
    },
    async unJoin_training(training_id, session_id) {
      this.loading = true;
      const { res, data } = await TrainingService.unJoin_training({
        training_id: training_id,
        session_id: session_id,
      });
      if (res.status == 200 && data.success) {
        showMessage("success", data.message);

        this.subList = this.subList.filter((el) => el != session_id);
        this.shouldUpdate = true;
        this.$emit("closeTrainingModal", this.shouldUpdate);
      } else {
        showMessage("error", data.message);
      }
      this.loading = false;
    },
    openScope(session) {
      if (!this.scopeOpened) {
        this.selectedSession = session;
        this.scopeOpened = true;

        this.$nextTick(() => {
          const selectedLevelRef = this.$refs["subscriptionScopeSelectionsRef"];
          if (selectedLevelRef) {
            selectedLevelRef.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
        });
      } else {
        if (session.id != this.selectedSession) {
          this.selectedSession = {};
          this.scopeOpened = false;
          setTimeout(() => {
            this.selectedSession = session;
            this.scopeOpened = true;
          }, 500);
        } else {
          this.selectedSession = {};
          this.scopeOpened = false;
        }
      }
    },
    findSessionModules() {
      if (this.selectedTraining) {
        let s = this.selectedTraining.sessions.find(
          (session) =>
            this.selectedTraining.users.find(
              (el) => el.user_id == this.userData.id
            ).sessions_id == session.id
        );
        return s.modules;
      }
    },
    async downloadFileWhenClicking(eventData, attachment) {
      try {
        const response = await fetch(attachment.url);
        const blob = await response.blob();

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = attachment.name; // Set the desired filename
        link.click();
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    },

    async downloadFile(sId, mId, id) {
      const { res, data } = await FilesService.getFileUrl(id, "modules");
      if (res.status == 200) {
        this.selectedTraining.sessions = this.selectedTraining.sessions.map(
          (session) => {
            if (
              this.selectedTraining.users.find(
                (el) => el.user_id == this.userData.id
              ).sessions_id == session.id
            ) {
              let m = session.modules.map((m) => {
                let f = m.files.map((file) => {
                  return file.id == id
                    ? { ...file, url: data.download_url }
                    : file;
                });
                return { ...m, files: f };
              });
              return { ...session, modules: m };
            } else {
              return session;
            }
          }
        );
        return data.download_url;
      }
    },
    formDate(date) {
      return moment(date).format("MM-DD-YYYY");
    },
  },
  watch: {
    trainingModalOpen(val) {
      this.dialogVisible = val;
      if (val) {
        this.training = this.selectedTraining;
        this.selectedUsersChanged(this.selectedTraining.users.map((el) => {}));
        if (
          Object.keys(this.training).includes("users") &&
          this.training.users.length > 0
        ) {
          this.training.users.forEach((user) => {
            if (user.user_id == this.userData.id) {
              this.subList.push(user.sessions_id);
            }
          });
          this.selectedTraining.sessions.map((session) => {
            if (
              this.selectedTraining.users.find(
                (el) => el.user_id == this.userData.id
              ).sessions_id == session.id
            ) {
              session.modules.map((m) => {
                m.files.map((file) => {
                  this.downloadFile(session.id, m.module_id, file.id);
                });
              });
            }
          });
        }
      } else {
        this.training = {};
        this.shouldUpdate = false;
        this.scopeOpened = false;
        this.selectedSession = {};
        this.selectedRoles = [];
        this.selectedLevels = [];
        this.selectedAllUsers = false;
        this.selectedUsers = false;
      }
    },
    selectedUsers(val) {},
  },
};
</script>

<style>
.el-button-group button {
  border-radius: 30px 0 0 30px;
  height: 30px;
}
.el-button-group .el-dropdown__caret-button {
  border-radius: 0 30px 30px 0;
  height: 30px;
}
.Training--details--modal.el-dialog {
  /* max-height: 80dvh;
    overflow: hidden; */
}
.Training--details--modal .el-dialog__body {
  max-height: 60vh;
  overflow-y: auto;
}
.closeBtn:hover {
  background-color: transparent;
  color: #081f8f !important;
}
</style>
<style scoped>
.main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.variables {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 0;
}

.variable {
  display: flex;
  flex-direction: column;
  width: calc(100% / 4);
}

.variable label {
  color: #7b7b7b;
  font-size: 12px;
}

.variable span {
  font-size: 16px;
}

.module--content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

thead {
  height: 50px;
  text-transform: capitalize;
  border: 1px solid #c3c3c3;
  border-bottom: 1px solid #434343;
}

tbody {
  border: 1px solid #c3c3c3;
}

.head--tr th,
.body--tr td {
  padding: 15px;
  height: calc(60px - 30px);
}

.session--detail {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
}

.MD--SS--date {
  font-size: 14px;
  color: #434343;
}

.MD--SS--time {
  font-size: 14px;
  color: #7b7b7b;
}

.Module--card {
  width: 100%;
  min-height: 150px;
  position: relative;
  padding: 15px;
  padding-left: 15px;
  border: 1px solid #c3c3c3;
  background-color: white;
  border-left: 4px solid;
  gap: 10px;
  border-left-color: #3399ff;
  display: flex;
  flex-direction: column;
}

/* .Module--card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
 
  height: 100%;
} */
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action--btn {
  background-color: transparent;
  border: none;
  outline: none;
}

.description {
  margin: 0;
  height: 40%;
  padding: 0;
}

.file {
  height: 50px;
  background: #f7fafe;
  width: calc(100% / 2 - 5px);
  border: 1px solid #e7f0fa;
  display: flex;
  padding: 15px;
  gap: 15px;
}

.points--container,
.duration--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
}

.points--title,
.Duration--title {
  font-weight: 600;
}
@media (max-width: 870px) {
  .main {
    max-height: calc(100vh - 125px);
  }
}
@media (max-width: 636px) {
  .file {
    width: calc(100% / 1);
  }
}

.modal-body {
  padding: 0 1rem !important;
}
::v-deep .modal-header {
  padding: 1rem 1rem 0 1rem !important;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
  padding: 10px;
  width: 105px;
  margin-left: 10px;
}

::v-deep .el-select__wrapper {
  border-radius: 0;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
}
::v-deep .el-textarea__inner {
  border-radius: 0;
}

::v-deep .modal-dialog {
  top: unset !important;
  left: unset !important;
}
</style>
