<template>
  <div
    class="Module--card"
    :style="['border-left-color:' + training.color]"
    @click="() => $emit('openTrainingModal', training)"
  >
    <div class="header">
      <h4>{{ training.name }}</h4>
    </div>
    <p class="description">
      {{ training.description }}
    </p>
    <div class="points--container">
      <span class="points--title">Points</span>
      <el-divider border-style="dashed" />
      <el-tag :type="primary" effect="plain" round>
        {{ generateTraining(training) }}
      </el-tag>
    </div>
    <div class="duration--container">
      <span class="Duration--title">Participants</span>
      <el-divider border-style="dashed" />
      <div class="d-flex gap-1">
        <el-tag :type="primary" effect="plain" round>
          {{ training.users ? training.users.length : 0 }} /
          {{ training.max_attendance }}
        </el-tag>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TrainingCard",
  props: ["training"],
  emits: ["openTrainingModal"],
  data() {
    return {};
  },
  methods: {
    generateTraining(training) {
      return training.sessions[0].modules.reduce(
        (prev, next) => prev + next.points,
        0
      );
    },
  },
};
</script>
<style>
@media (max-width: 870px) {
  .main {
    height: calc(100vh - 125px);
  }
  .Module--card {
    width: calc(100% / 1);
  }
}
.trainings {
  height: fit-content;
  overflow: auto;
}
</style>
<style scoped>
h4 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Module--card {
  width: calc(100% / 5 - 7px);
  height: 210px;
  position: relative;
  padding: 15px;
  padding-left: 15px;
  border: 1px solid #c3c3c3;
  background-color: white;
  border-left: 4px solid;
  gap: 10px;
  border-left-color: #3399ff;
  cursor: pointer;
}

/* .Module--card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
 
  height: 100%;
} */
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.action--btn {
  background-color: transparent;
  border: none;
  outline: none;
}
.description {
  margin: 0;
  height: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.points--container,
.duration--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 30px;
}
.points--title,
.Duration--title {
  font-weight: 600;
}
@media (max-width: 970px) {
  .Module--card {
    width: calc(100% / 2 - 7px);
  }
}

@media (max-width: 870px) {
  .main {
    height: calc(100vh - 125px);
  }
  .Module--card {
    width: calc(100% / 1);
  }
}
</style>
